@import "../../styles/settings";

.formTextarea {
    padding: rem(15px) rem(10px);
    border-radius: $radius;
    transition: background-color $speed $easing;
    resize: none;
    background-color: $gray;

    &:focus {
        background-color: darken($gray, 5%);
    }

    @media (max-width: $to-medium) {
        height: rem(100px);
    }

    @media (min-width: $from-medium) {
        height: rem(160px);
    }

    &::placeholder {
        font-size: rem(13px);
        color: $gray-light;

        @media (min-width: $from-medium) {
            font-size: rem(25px);
            text-align: right;
        }
    }
}


.formTextarea_button_wrapper {
    text-align: right;
    padding-top: rem(12px);

    @media (min-width: $from-medium) {
        text-align: left;
    }
}

