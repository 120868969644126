@import "../../styles/settings";

.nav {
    position: absolute;
    display: flex;

    @media (max-width: $to-small) {
        bottom: 0;
        left: 60px;
    }

    @media (min-width: $from-small) {
        top: 0;
        right: 0;
        margin-top: rem(37px);
    }
}

.nav_list {
    @include u-list-reset;
}

.nav_item {
    @media (max-width: $to-small) {
        display: inline-block;
        margin-left: 1px;
    }

    @media (min-width: $from-small) {
        margin-bottom: 1px;
    }
}

.nav_link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: $radius;
    text-transform: uppercase;
    background-color: $white;
    color: $color;
    line-height: 1;
    font-weight: $bold;
    letter-spacing: 0.091em;
    transition: background-color $speed $easing;

    @media (max-width: $to-small) {
        width: 26vw;
        height: 40px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 9px;
    }

    @media (min-width: $from-small) {
        width: rem(46px);
        height: 20vh;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-size: rem(10px);
    }

    &:hover {
        color: $color;
        background-color: darken($white, 10%);
    }

    &:focus, &.is-active {
        background-color: $blue;
        color: $white;
    }
}

.nav_link_inner {
    opacity: 0.63;

    @media (min-width: $from-small) {
        transform: rotate(180deg);
    }

    .nav_link:focus, .nav_link.is-active {
        & {
            opacity: 1;
        }
    }
}
