@import "../../styles/settings";

.header {
    z-index: 11;
    background-color: $black-light;
    color: $white;

    @media (max-width: $to-small) {
        position: relative;
        height: rem(125px);
        padding: rem(20px) rem(15px);
        transition: transform 0s $easing;
        transform: translateY(-100%);

        .has-transition-appear-active &,
        .has-transition-enter-active &,
        .has-transition-enter-done & {
            transform: none !important;
        }

        .has-transition-appear-active & {
            transition-duration: $speed;
            transition-delay: 0.6s;
        }
    }

    @media (min-width: $from-small) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: rem(-$header);
        width: rem($header);
        transition: transform 0s $easing;

        .has-transition-appear-active &,
        .has-transition-enter-active &,
        .has-transition-enter-done & {
            transform: translateX(100%) !important;
        }

        .has-transition-appear-active & {
            transition-duration: $speed;
            transition-delay: 0.6s;
        }
    }

    .authView & {
        background-color: transparent;
        color: $blue;
    }
}

.header_logo {
    display: inline-block;

    @media (max-width: $to-small) {
        width: rem(30px);
        height: rem(67px);
    }

    @media (min-width: $from-small) {
        position: absolute;
        right: 0;
        bottom: rem(190px);
        left: 50%;
        width: rem(50px);
        height: rem(109px);
        margin-left: rem(-25px);
    }

    @media (min-width: $from-large) {
        bottom: rem(140px);
        width: rem(36px);
        margin-left: rem(-18px);
    }

    svg {
        // fill: $white;
    }
}

.header_hi {
    color: $white;
    position: absolute;

    @media (max-width: $to-small) {
        display: inline-block;
        vertical-align: top;
        top: rem(20px);
        left: rem(135px);
        width: 40vw;

        .heading.-h1 {
            font-size: rem(18px);
        }
    }

    @media (min-width: $from-small) {
        top: 50%;
        left: 0;
        transform: rotate(180deg) translateY(50%);
        writing-mode: vertical-rl;
        text-orientation: mixed;
        padding-left: rem(5px);
    }
}

.header_version {
    position: absolute;
    top: 0;
    opacity: 0.3;
    font-weight: $bold;

    @media (max-width: $to-small) {
        right: 0;
        font-size: rem(9px);
        margin: rem(4px) rem(6px);
    }

    @media (min-width: $from-small) {
        left: 0;
        margin: rem(8px) rem(10px);
        font-size: rem(9px);
    }
}

.header_langswitch {
    position: fixed;
    top: 5px;
    left: 10px;

    @media (max-width: $to-small) {
        display: none;
    }
}

.header_langswitch_item {
    text-transform: uppercase;
    color: $white;
    font-size: 11px;

    &:hover {
        color: currentColor;
        text-decoration: underline;
    }
}
