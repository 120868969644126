@import "../../styles/settings";

.formNotifications {
    background-color: $gray-light;
    border: 1px solid rgba($gray-medium, 0.5);
    border-radius: $radius;
    list-style: none;
    margin: 0;
    padding: rem(15px);

    &.has-errors {
        border-color: rgba($red, 0.5);
        background-color: rgba($red, 0.5);
    }

    &.has-success {
        border-color: rgba($green, 0.5);
        background-color: rgba($green, 0.5);
    }
}

.formNotifications_item {
    margin: 0;

    & + & {
        margin-top: rem(7px);
    }
}
