@import "../../styles/settings";

.lightboxVideo {
    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .lightbox_main {
        padding: 0;
        background-color: $black;

        &::before {
            display: none;
        }
    }
}

.lightboxVideo_main {
    position: relative;
    padding-bottom: 56.2%;
}
