@import "../../styles/settings";

$checkbox: rem(18px);
$checkbox-icon-color: $input-icon-color;

.formFancyCheckbox {
    position: relative;
    width: rem(50px);
    height: rem(28px);
    background-color: $gray;
    border-radius: rem(50px);
    transition: background-color $speed $easing;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: rem(24px);
        height: rem(24px);
        border-radius: 50%;
        transform: translate3d(#{rem(2px)}, -50%, 0);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
        background-color: $white;
        transition: transform $speed $easing;
    }

    &:checked {
        background-color: $green;

        &::after {
            transform: translate3d(#{rem(24px)}, -50%, 0);
        }
    }
}
