@import "../../styles/settings";

.card {
    position: relative;
    background-color: $white;
    border: 1px solid $gray-light;
    border-radius: $radius;
    // padding: rem(30px) rem(20px);
    padding: rem(12px);
    color: $color;

    &:not(.-no-margin) {
        @media (max-width: $to-large) {
            margin-bottom: rem(20px);
        }

        @media (min-width: $from-large) {
            margin-bottom: rem(40px);
        }
    }

    &.-small {
        padding: rem(10px);
    }

    &.-sides {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.-header {
        padding: 0;
        // overflow: hidden;
    }

    &.-full {
        width: 100%;
    }

    &.-auth {
        margin: 0;

        @media (max-width: $to-small) {
            max-width: rem(420px);
            width: calc(100vw - 60px);
        }

        @media (min-width: $from-small) {
            width: rem(420px);
        }
    }
}
