@import "../../styles/settings";

.quickNav {
    position: fixed;
    top: rem(100px);
    left: 0;
    padding: rem($unit-half);
    color: $black;
    z-index: 100;
    font-size: 1rem;
    text-align: left;

    @media (max-width: $to-large) {
        display: none;
    }
}

.quickNav_list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.quickNav_list_item {
    display: block;

    button {
        display: block;
        padding: rem(5px) 0;
        color: $gray-light;
        transition: color $speed $easing;

        &:hover {
            color: $blue;
        }
    }
}
