// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
//
html {
    color: $color;
    font-family: $font-family;
    line-height: $line-height; /* [1] */
    background-color: $gray;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: $to-small) {
        font-size: 14px;
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        font-size: 13px;
    }

    @media (min-width: $from-medium) and (max-width: $to-big) {
        font-size: 14px;
    }

    @media (min-width: $from-big) and (max-width: $to-huge) {
        font-size: $font-size; /* [1] */
    }

    @media (min-width: $from-huge) and (max-width: $to-gigantic) {
        font-size: 16px;
    }

    @media (min-width: $from-gigantic) and (max-width: $to-colossal) {
        font-size: 18px;
    }

    @media (min-width: $from-colossal) {
        font-size: 20px;
    }
}

body {
    overscroll-behavior-y: none;
}

::selection {
    background-color: $selection-background-color;
    color: $selection-text-color;
    text-shadow: none;
}

a {
    color: $link-color;
    transition: color $speed $easing;

    &:hover {
        color: $link-hover-color;
    }

}

figure {
    margin: 0 auto;
}

p {
    margin: 0;
}

// main {
//     transition: opacity $speed $easing $speed;
//     opacity: 0;

//     .has-transition-enter-done:not(.has-transition-enter-active) & {
//         opacity: 1;
//         transition-delay: 0s;
//     }

//     .has-transition-enter-active & {
//         opacity: 0;
//         transition-duration: 0s;
//         transition-delay: 0s;
//     }
// }

strong {
    font-weight: $bold;
}
