@import "../../styles/settings";

.page {
    background-color: $gray-light;
    display: flex;
    min-height: 100vh;
    position: relative;
    width: 100%;

    &.-login {
        // background-color: $blue;
        background-color: #EAEAFF;
        background-image: url('../../assets/icons/background.svg');
        background-position-y: -60px;
    }
}

.page_sidebar {
    @media (max-width: $to-small) {
        display: none;
    }
    @media (min-width: $from-small) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 20%;
    }
}

.page_main {
    @media (max-width: $to-small) {
        padding-top: rem(50px);
    }
    @media (min-width: $from-small) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-left: 20%;
        width: 80%;

        .page.-login & {
            align-items: center;
        }
    }
}


// .page_logo_station_container {
//     @media (max-width: $to-small) {
//         position: absolute;
//         right: 0;
//         left: 0;
//         text-align: center;
//         top: rem(30px);
//     }
//     @media (min-width: $from-small) {
//         left: 0;
//         position: fixed;
//     }
//     @media (min-width: $from-small) and (max-width: $to-large) {
//         top: rem(80px);
//         transform: rotate(-90deg);
//     }
//     @media (min-width: $from-large) {
//         left: rem(30px);
//     }
//     @media (min-width: $from-large) and (max-width: $to-big) {
//         top: rem(50px);
//     }
//     @media (min-width: $from-big) and (max-width: $to-huge) {
//         top: rem(60px);
//     }
//     @media (min-width: $from-huge) {
//         top: rem(55px);
//     }
// }

// .page_logo_station {
//     display: inline-block;
//     height: rem(35px);
//     color: $white;
//     fill: $white;

//     .page.-landing & {
//         color: $blue;
//     }

//     @media (max-width: $to-small) {
//         width: rem(100px);
//     }
//     @media (min-width: $from-small) {
//         width: rem(130px);
//     }
// }
