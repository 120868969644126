@use "sass:math";
@import "../../styles/settings";

.timeline {
    position: relative;

    @media (max-width: $to-medium) {
        &.-center {
            padding: 20px 0;
        }
    }

    @media (min-width: $from-medium) {
        &.-center {
            padding: rem(15px);
        }
    }
}

.timeline_main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: rem(24px);
    background-color: $gray;
    margin-right: rem(28px);
    border-radius: rem(12px);

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: rem(11px);
        bottom: 0;
        border-left: 2px solid $gray-dark;
    }

    @media (max-width: $to-small) {
        &::before {
            margin: 20px 0;
        }
    }

    @media (min-width: $from-small) {
        .timeline.-center & {
            left: 50%;
            margin-left: rem(-12px);
            margin-right: 0;
        }
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        &::before {
            margin: rem(30px) 0;
        }

    }

    @media (min-width: $from-medium) {
        &::before {
            margin: rem(15px) 0 rem(30px);
        }
    }
}

.timeline_item {
    position: relative;
    padding-left: rem(55px);
    z-index: 1;

    &.is-done {
        &::before, + .timeline_item::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: rem(10px);
            width: rem(4px);
            height: 50%;
            // background-color: $green;
        }

        + .timeline_item::before {
            bottom: auto;
            top: 0;
        }

        &:not(:last-child)::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: rem(10px);
            width: rem(4px);
            height: 50%;
            // background-color: $green;
            z-index: -1;
        }
    }

    .timeline:not(.-center) & {
        &.is-done {
            &::before, + .timeline_item::before {
                background-color: $blue;
            }

            &:not(:last-child)::after {
                background-color: $blue;
            }
        }
    }

    .timeline.-center & {
        padding-left: 0;
        font-size: rem(12px);

        &.is-done {
            &::before {
                height: 100%;
                background-color: $green;


                .is-completed & {
                    background-color: $blue;
                }
            }
        }

        &.is-current {
            &::before {
                display: none;
            }
        }

        &:not(:last-child)::after {
            content: none;
        }
    }

    @media (min-width: $from-small) {
        .timeline.-center & {
            padding-bottom: rem(50px);

            &::before {
                left: 50%;
                margin-left: rem(-2px);
            }
        }
    }
}

.timeline_item_dot {
    position: absolute;
    top: 50%;
    left: rem(8px);
    width: rem(8px);
    height: rem(8px);
    border: 2px solid $gray-dark;
    margin-top: rem(-4px);
    background-color: $gray;
    border-radius: 50%;
    z-index: 1;

    .timeline_item.is-done & {
        background-color: $blue;
        width: rem(16px);
        height: rem(16px);
        left: rem(4px);
        margin-top: rem(-8px);
        border: none;

        svg {
            stroke: $white;
        }
    }

    .timeline_item.is-current & {
        border: none;
        background-color: $green;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.27);
        color: $white;
        font-weight: $medium;
        padding: rem(3px);

        @media (max-width: $to-small) {
            width: 40px;
            height: 40px;
            margin-top: -20px;
            font-size: 11px;
            left: -10px;
        }
        @media (min-width: $from-small) and (max-width: $to-huge) {
            font-size: rem(13px);
            width: rem(40px);
            height: rem(40px);
            margin-top: rem(-20px);
            left: rem(-8px);
        }
        @media (min-width: $from-huge) {
            font-size: rem(11px);
            width: rem(50px);
            height: rem(50px);
            margin-top: rem(-25px);
            left: rem(-13px);
        }

        .is-completed & {
            background-color: $blue;
        }
    }

    .timeline.-center .timeline_item.is-done & {
        background-color: $blue;

        .is-completed & {
            background-color: $blue;
        }
    }

    .timeline.-center .timeline_item.is-current & {
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: rem(6px);
            height: rem(6px);
            background-color: $white;
            border-radius: 50%;
            margin-left: rem(-3px);
            margin-top: rem(-3px);
        }
    }

    @media (max-width: $to-small) {
        .timeline.-center & {
            margin-top: 0;
            top: 0;
        }

        .timeline.-center .timeline_item.is-current & {
            z-index: 2;
        }
    }

    @media (min-width: $from-small) {
        .timeline_item.is-done & {
            margin-top: rem(-8px);
        }

        .timeline.-center & {
            top: 0;
            left: 50%;
            margin-top: 0;
            margin-left: rem(-4px);
        }

        .timeline.-center .timeline_item.is-done & {
            margin-left: rem(-8px);
        }

        .timeline_sub & {
            display: none;
        }
    }

    @media (min-width: $from-small) and (max-width: $to-huge) {
        .timeline.-center .timeline_item.is-current & {
            margin-left: rem(-20px);
        }
    }
    @media (min-width: $from-huge) {
        .timeline.-center .timeline_item.is-current & {
            margin-left: rem(-25px);
        }
    }
}

.timeline_item_dot_wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .timeline.-center & {
        display: none;
    }
}

.timeline_item_dot_text {
    position: absolute;
    font-size: rem(11px);
}

.timeline_sub {
    @media (max-width: $to-small) {
        position: relative;
        display: flex;

        &.-empty {
            display: none;
        }
    }

    @media (min-width: $from-small) {
        display: inline-flex;
        align-items: flex-start;
        width: 50%;

        &:first-child {
            text-align: right;
            flex-direction: row-reverse;

            .timeline_sub_content p {
                margin-left: auto;
            }
        }

        &.-empty {
            visibility: hidden;
        }
    }
}

.timeline_sub_content {
    display: inline-block;
    line-height: math.div(15,11);

    @media (max-width: $to-small) {
        font-size: 11px;

        .timeline.-center & {
            margin-bottom: 50px;
        }
    }

    @media (min-width: $from-small) {
        font-size: rem(12px);
    }

    p {
        max-width: rem(175px);
    }

    .timeline_item.is-current & {
        margin-top: rem(17px);
    }

    .timeline_item.is-upcoming & {
        margin-top: rem(-4px);
        opacity: 0.5;
    }
}

.timeline_sub_line {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: rem(4px);

    &::before {
        content: "";
        display: block;
        border-top: 2px solid $gray;
    }

    &::after {
        content: "";
        position: absolute;
        top: rem(-4px);
        right: 0;
        display: inline-block;
        width: rem(10px);
        height: rem(10px);
        background-color: $gray;
        border-radius: 50%;
    }

    .timeline_item.is-done & {
        margin-top: rem(8px);
    }

    .timeline_item.is-current & {
        margin-top: rem(25px);
    }

    .timeline_sub:first-child & {
        padding-right: rem(25px);
        padding-left: rem(20px);
    }

    .timeline_sub:last-child & {
        padding-left: rem(25px);
        padding-right: rem(20px);
    }

    @media (max-width: $to-small) {
        width: 33%;
        margin-left: 10px;

        &::after {
            right: rem(20px);
        }
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        width: 37%;
    }

    @media (min-width: $from-medium) {
        width: 26.7%;
    }

    @media (min-width: $from-small) {
        .timeline_sub:first-child & {
            &::after {
                left: rem(20px);
            }
        }

        .timeline_sub:last-child & {
            &::after {
                right: rem(20px);
            }
        }
    }
}
