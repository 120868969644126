@import "../../styles/settings";

.cardContact {
    font-weight: $medium;
    line-height: 1.4;

    &.-large {
        .card {
            @media (max-width: $to-small) {
                padding: 0;
            }

            @media (min-width: $from-medium) and (max-width: $to-large) {
                padding: 0 rem(5px);
            }
        }
    }
}

.cardContact_wrap {
    display: flex;
    align-items: center;
    padding: rem(10px) 0;

    .cardContact.-border & {
        border-bottom: 1px solid $gray;

        @media (max-width: $to-small) {
            padding-bottom: 30px;
        }

        @media (min-width: $from-small) {
            padding-bottom: rem(60px);
        }
    }
}

.cardContact_image {
    position: relative;
    width: rem(100px);
    height: rem(100px);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 1px $gray-light;
    background-color: $gray;
    overflow: hidden;

    @media (max-width: 320px) {
        margin-left: -15px;
        margin-right: 5px;
    }

    @media (min-width: 321px) and (max-width: $to-small) {
        margin-right: 15px;
    }

    @media (min-width: $from-small) {
        margin-right: rem(30px);
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        .cardContact.-large & {
            margin-right: rem(20px);
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

