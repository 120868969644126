@import "../../styles/settings";

.navActions {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    font-size: 1rem;

    &.is-open {
        z-index: 2;
    }
}

.navActions_button {
    position: relative;
    width: rem(40px);
    height: rem(40px);
    display: inline-block;
    vertical-align: middle;
    z-index: 1;

    &::before{
        content:"";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $blue-light;
        z-index: -1;
        border-radius: 50%;
        transition: transform $speed $easing;
        transform: scale3d(0,0,0);
    }

    .navActions.is-open & {
        &::before{
            transform: scale3d(1,1,1);
        }
    }
    &:hover {
        &::before{
            transform: scale3d(1,1,1);
        }
    }
}

.navActions_button_dots {
    width: 4px;
    height: 4px;
    display: block;
    background-color: $white;
    border-radius: 50%;
    margin: auto;

    &::before,
    &::after {
        content:"";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4px;
        height: 4px;
        display: block;
        background-color: $white;
        border-radius: 50%;
        margin: auto;
    }

    .navActions.-dark &,
    .navActions.-dark &::before,
    .navActions.-dark &::after {
        background-color: #4f4f4f;
    }

    &::before{
        transform: translate3d(-50%,-50%,0) translate3d(0,-8px,0);
    }
    &::after{
        transform: translate3d(-50%,-50%,0) translate3d(0,8px,0);
    }
}

.navActions_nav_list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.navActions_nav {
    pointer-events: none;
    opacity: 0;
    transition: opacity $speed $easing;
    background-color: $white;
    color: $black;
    position: absolute;
    left: 0;
    z-index: 1000;
    border-radius: 6px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    min-width: rem(200px);
    top: calc(100% + 15px);
    overflow: hidden;

    .navActions.is-open & {
        pointer-events: all;
        opacity: 1;
    }

    .navActions.-right & {
        right: 0;
        left: auto;
    }

    .navActions.-bottom & {
        bottom: calc(100% + 15px);
        top: auto;
    }
}

.navActions_nav_item {

    a, button {
        display: block;
        width: 100%;
        text-align: left;
        padding: rem(15px);
        color: $black;
        transition: background-color $speed $easing;

        &:hover {
            background-color: $gray;
        }
    }
}
