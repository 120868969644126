@import "../../styles/settings";

.view {
    position: relative;
    z-index: 2;
    transition: opacity $speed-slow $easing;

    @media (min-width: $from-small) {
        padding-left: rem($header);
    }

    .has-transition-enter-active &{
        z-index: 1;
    }

    &.has-introduction {
        overflow: hidden;
        height: 100vh;
        opacity: 0;
        transition-duration: 0s;
        transition-delay: 0s;
    }
}
