@import '../../styles/settings';

.cardList.-accordion {
}

.cardList_toggler {
    .cardList.-accordion & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:hover, &:focus-visible {
            background-color: $gray;
            transition: background-color $speed $easing;
        }
    }
}

.cardList_heading {
    .cardList.-accordion & {
        position: relative;
        padding: rem(10px) rem(5px);
    }
}

.cardList_container {
    .cardList.-accordion & {
        height: 0px;
        overflow: hidden;
        transition: height $speed $easing;
    }
    .cardList.-accordion.is-deploy & {
        height: var(--content-height, 100%);
    }
}

.cardList_edit {
    .cardList.-accordion & {
        margin-bottom: 0;
        pointer-events: auto;
    }
}

.cardList_title {
    .cardList.-accordion & {
        position: relative;
        margin-left: rem(10px);
        margin-bottom: 0;
        z-index: 1;
        pointer-events: none;
    }
}

.cardList_list {
    .cardList.-accordion & {
        padding-top: rem(10px);
    }
}

.cardList_actions {
    .cardList.-accordion & {
        position: relative;
        z-index: 1;
        pointer-events: none;
    }
}

.cardList_accordion-icon {
    .cardList.-accordion & {
        position: relative;
        width: rem(14px);
        height: rem(14px);
        z-index: 1;
        pointer-events: none;
    }

    .cardList.-accordion.is-deploy & {
        transform: scaleY(-1);
    }
}

.cardLink_help {
    .cardList.-accordion & {
        margin-top: rem(10px);
    }
}