@import "../../styles/settings";

.projectCards {
    @include u-list-reset;

    display: grid;
    overflow: hidden;
    grid-column-gap: rem(24px);
    grid-row-gap: rem(24px);

    @media (min-width: $from-small) and (max-width: $to-large) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: $from-large) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.projectCards_item {
    background-color: $white;
    border: 1px solid #F0F0F0;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

// .projectCards_container {
//     position: relative;
//     z-index: 0;
// }

.projectCards_link {
    position: absolute;
    z-index: 1;
    text-decoration: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.projectCards_visual {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-dark;
}

.projectCards_visual_label {
    position: absolute;
    padding: rem(10px);
    border: 1px solid $white;
    border-radius: 20px;
    color: $white;
    max-width: 80%;
    text-align: center;
}

.projectCards_content {
    // position: relative;
    padding: rem(12px);
}

.projectCards_header,
.projectCards_footer {
    padding: rem(12px);
}

.projectCards_header {
    display: flex;

    padding-bottom: rem(24px);
}

.projectCards_titles {
    padding-right: rem(12px);
    width: 80%;
}

.projectCards_actions {
    display: flex;
    width: 20%;
    justify-content: right;
}

.projectCards_title {
    font-size: rem(16px);
    font-weight: $normal;
    margin: 0;

    &.-gray {
        color: $gray-dark;
    }

    & + & {
        margin-top: rem(4px);
    }
}

.projectCards_favorite {
    color: $red;
    font-size: rem(12px);
    height: rem(24px);
    width: rem(24px);
    margin-right: rem(9px);
}

.projectCards_footer {
    border-top: 1px solid #F0F0F0;
}

.projectCards_footer_row {
    display: flex;
    justify-content: space-between;

    & + & {
        margin-top: rem(4px);
    }
}

.projectCards_footer_label,
.projectCards_footer_value {
    font-weight: $normal;
    flex-basis: 50%;
    font-size: rem(13px);
    margin: 0;
}

.projectCards_footer_label {
    color: $gray-dark;
}

.projectCards_buttons {
    @include u-list-reset;
    text-align: right;
    z-index: 2;

    .navActions_button {
        width: rem(30px);
        height: rem(30px);
    }
}