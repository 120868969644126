@import "../../styles/settings";

$checkbox: rem(18px);
$checkbox-icon-color: 000000;

.formCheckbox {
    position: absolute;
    width: 0;
    opacity: 0;

    &:checked {
        +.formCheckbox_label {
            &::after {
                opacity: 1;
                transform: scale(1);
            }
        }

        +.formCheckbox_label.-radio {
            background-color: $black;
            color: $white;
        }
    }
}

.formCheckbox_label {
    position: relative;
    display: inline-block;
    margin-right: 0.5em;
    padding-left: ($checkbox + rem(20px));
    text-align: left;
    font-size: 1rem;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        padding: 0;
        width: $checkbox;
        height: $checkbox;
        content: "";
        border-radius: $radius;
        cursor: pointer;
    }

    &::before {
        background-color: $gray;
        transition: background-color $speed $easing;

        &:hover {
            background-color: $gray;
        }
    }

    &::after {
        border-color: transparent;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2210.5%22%20viewBox%3D%220%200%2013%2010.5%22%20enable-background%3D%22new%200%200%2013%2010.5%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23#{$checkbox-icon-color}%22%20d%3D%22M4.8%205.8L2.4%203.3%200%205.7l4.8%204.8L13%202.4c0%200-2.4-2.4-2.4-2.4L4.8%205.8z%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: rem(13px);
        background-repeat: no-repeat;
        opacity: 0;
        transform: scale(0);
        transition: transform $speed $easing, opacity $speed $easing;
        transform-origin: bottom left;
    }

    &.-radio {
        border: 1px solid $black;
        border-radius: 6px;
        padding: rem(15px);
        transition: backgroud-color $speed $easing, color $speed $easing;

        &::before,
        &::after {
            display: none;
        }
    }
}
