@import "../../styles/settings";

.pointer {
    position: absolute;
    pointer-events: none;
    z-index: 1000;
    color: $black;

    &.is-active {
        pointer-events: all;
    }

    @media (min-width: $from-medium) {

        &.-left {
            right: calc(100% + 25px);
        }
        &.-right {
            left: calc(100% + 25px);
        }
        &.-top {
            bottom: calc(100% + 25px);
        }
        &.-bottom {
            top: calc(100% + 25px);
        }

        &.-align-bottom {
            bottom: 0;
        }
        &.-align-top {
            top: 0;
        }
        &.-align-left {
            left: 0;
        }
        &.-align-right {
            right: 0;
        }
        &.-align-centerX {
            left: 50%;
            transform: translateX(-50%);
        }
        &.-align-centerY {
            top: 50%;
            transform: translateY(-50%);
        }
    }
    @media (max-width: $to-medium) {

        &.-mobile-top {
            bottom: calc(100% + 15px);
        }
        &.-mobile-bottom {
            top: calc(100% + 15px);
        }
        &.-mobile-align-left {
            left: 0;
        }
        &.-mobile-align-right {
            right: 0;
        }
    }
}

.pointer_wrap {
    background-color: $white;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: rem(20px);
    border-radius: 10px;
    opacity: 0;
    position: relative;
    z-index: 1;
    transform: translate3d(0,60px,0);
    transition: opacity $speed-slow $easing, transform $speed-slow $easing;
    width: rem(320px);

    .pointer.is-active & {
        opacity: 1;
        transform: translate3d(0,0,0);
        transition-delay: 0.6s;
    }

    &::before {
        content:"";
        position: absolute;
        background-color: $white;

        @media (min-width: $from-medium) {
            width: 20px;
            height: 20px;
        }
        @media (max-width: $to-medium) {
            width: 15px;
            height: 15px;
        }
    }

    @media (min-width: $from-medium) {

        &::before {

            .pointer.-left & {
                left: calc(100% - 10px);
            }
            .pointer.-right & {
                right: calc(100% - 10px);
            }
            .pointer.-top & {
                top: calc(100% - 10px);
            }
            .pointer.-bottom & {
                bottom: calc(100% - 10px);
            }

            .pointer.-align-bottom & {
                bottom: 25px;
                transform: rotate(45deg);
            }
            .pointer.-align-top & {
                top: 25px;
                transform: rotate(45deg);
            }
            .pointer.-align-left & {
                left: 25px;
                transform: rotate(45deg);
            }
            .pointer.-align-right & {
                right: 25px;
                transform: rotate(45deg);
            }
            .pointer.-align-centerX & {
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
            }
            .pointer.-align-centerY & {
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }
    @media (max-width: $to-medium) {
        max-width: calc(100vw - 60px);

        &::before {
            transform: rotate(45deg);

            .pointer.-mobile-top &{
                top: calc(100% - 7.5px);
            }
            .pointer.-mobile-bottom &{
                bottom: calc(100% - 7.5px);
            }
            .pointer.-mobile-align-left &{
                left: 15px;
            }
            .pointer.-mobile-align-right &{
                right: 15px;
            }
            .pointer.-align-centerX & {
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
            }
        }
    }
}

.pointer_title {
    margin-top: 0;
}

.pointer_actions {
    margin-top: rem(30px);
}

.pointer_action_button {
    padding: rem(10px);
    background-color: $gray;
    color: $blue;
    border-radius: 20px;
    transition: background-color $speed $easing;

    &:hover {
        background-color: darken($gray,5%);
    }
}
