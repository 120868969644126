@import "../../styles/settings";

.timelineCheckbox_wrap {
    background-color: $white;
    border-radius: 999px;
    border: 1px solid $black;
    color: $black;
    text-align: left;
    margin-top: rem(10px);
    transition: border-color $speed $easing, color $speed $easing, background-color $speed $easing;
    display: inline-block;

    &:hover {
        cursor: pointer;
    }

    &.is-checked {
        background-color: $blue;
        color: $white;
        border-color: $blue;
    }
}

$checkbox: rem(18px);
$checkbox-icon-color: 3777E3;

.timelineCheckbox {
    position: absolute;
    width: 0;
    opacity: 0;

    &:checked {
        +.timelineCheckbox_label {
            &::after {
                opacity: 1;
                transform: translate3d(0,-50%, 0) scale(1);
            }
        }
    }
}

.timelineCheckbox_label {
    position: relative;
    display: inline-block;
    text-align: left;
    font-size: 1rem;
    width: 100%;
    padding: rem(8px) rem(10px) rem(8px) ($checkbox + rem(18px));

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        left: rem(8px);
        display: inline-block;
        padding: 0;
        width: $checkbox;
        height: $checkbox;
        content: "";
        border-radius: $radius;
        cursor: pointer;
        border-radius: 50%;
        transform: translate3d(0,-50%, 0);
    }

    &::before {
        background-color: $white;
        transition: background-color $speed $easing;
        border: 1px solid $gray-dark;

        .timelineCheckbox_wrap.is-checked & {
            border: 1px solid $white;
        }
    }

    &::after {
        border-color: transparent;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2210.5%22%20viewBox%3D%220%200%2013%2010.5%22%20enable-background%3D%22new%200%200%2013%2010.5%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23#{$checkbox-icon-color}%22%20d%3D%22M4.8%205.8L2.4%203.3%200%205.7l4.8%204.8L13%202.4c0%200-2.4-2.4-2.4-2.4L4.8%205.8z%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: rem(13px);
        background-repeat: no-repeat;
        opacity: 0;
        transform: translate3d(0,-50%, 0) scale(0);
        transition: transform $speed $easing, opacity $speed $easing;
        transform-origin: bottom left;
    }

    &:hover {
        cursor: pointer;
    }
}
