@import "../../styles/settings";

.authView {
    background-color: $blue;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    width: 100%;

    @media (min-width: $from-small) and (max-width: $to-big) {
        &.-landing {
            padding-left: rem(50px);
        }
    }
    @media (max-width: $to-large) {
        align-items: flex-start;
        padding-top: rem(90px);
    }
    @media (min-width: $from-large) {
        align-items: center;
    }

    &.-landing {
        display: block;
        background-color: $white;
        padding-top: 0;
    }
}

.authView_logo_station_container {
    @media (max-width: $to-small) {
        position: absolute;
        right: 0;
        left: 0;
        text-align: center;
        top: rem(30px);
    }
    @media (min-width: $from-small) {
        left: 0;
        position: fixed;
    }
    @media (min-width: $from-small) and (max-width: $to-large) {
        top: rem(80px);
        transform: rotate(-90deg);
    }
    @media (min-width: $from-large) {
        left: rem(30px);
    }
    @media (min-width: $from-large) and (max-width: $to-big) {
        top: rem(50px);
    }
    @media (min-width: $from-big) and (max-width: $to-huge) {
        top: rem(60px);
    }
    @media (min-width: $from-huge) {
        top: rem(55px);
    }
}

.authView_logo_station {
    display: inline-block;
    height: rem(35px);
    color: $white;
    fill: $white;

    .authView.-landing & {
        color: $blue;
    }

    @media (max-width: $to-small) {
        width: rem(100px);
    }
    @media (min-width: $from-small) {
        width: rem(130px);
    }
}
