@import "../../styles/settings";

$font-size-h1:  24px  !default;
$font-size-h2:  22px  !default;
$font-size-h3:  18px  !default;
$font-size-h4:  16px  !default;
$font-size-h5:  14px  !default;
$font-size-h6:  12px  !default;

@mixin h {
    margin-top: 0;
    line-height: 1.3;
    font-weight: $normal;

    &.-medium {
        font-weight: $medium;
    }

    &.-noMargin {
        margin-bottom: 0 !important;
    }

    &.-blue {
        color: $blue;
    }

    &.-green {
        color: $green;
    }

    &.is-completed {
        // color: $green;
        margin-bottom: 0 !important;
    }
}

.heading {
    @include h;

    &.-h1 {
        @media (max-width: $to-small) {
            font-size: 24px;
        }

        @media (min-width: $from-small) {
            font-size: rem($font-size-h1);
        }

        @media (max-width: $to-large) {
            margin-bottom: 20px;
        }

        @media (min-width: $from-large) {
            margin-bottom: rem(30px);
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
    }

    &.-h3 {
        @media (max-width: $to-small) {
            font-size: 16px;
        }
        @media (max-width: $to-huge) and (min-width: $from-small) {
            font-size: rem(16px);
        }
        @media (min-width: $from-huge) {
            font-size: rem($font-size-h3);
        }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
    }

    &.-h5 {
        font-size: rem($font-size-h5);
        margin-bottom: rem(5px);
    }

    &.-h6 {
        font-size: rem($font-size-h6);
        margin-bottom: rem(10px);
    }
}
