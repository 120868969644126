@import "../../styles/settings";

.companySelector_form {
    padding: rem($unit);
    background-color: $gray;

    input {
        background-color: $white;
    }
}
