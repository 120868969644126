@import "../../styles/settings";

.userMenu {
    position: absolute;
    display: flex;
    justify-content: center;

    @media (max-width: $to-small) {
        top: 4px;
        right: $padding-small;
    }

    @media (min-width: $from-small) {
        right: 0;
        bottom: rem(30px);
        left: 0;
    }
}

.userMenu_toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.userMenu_toggle_avatar {
    width: rem(50px);
    height: rem(50px);
    border-radius: 50%;
    border: 2px solid white;
    z-index: 100;
    transition: filter $speed-fast;
    margin-top: rem(4px);
    cursor: pointer;

    @media (max-width: $to-small) {
        margin-bottom: 2px;
    }

    @media (min-width: $from-small) {
        margin-bottom: rem(8px);
    }

    &.-default {
        background-color: $blue;
    }

    .userMenu_toggle:hover & {
        filter: brightness(0.8);
    }
}

.userMenu_toggle_label {
    color: currentColor;
    font-size: rem(12px);
    transition: color $speed-fast;

    .userMenu_toggle:hover & {
        color: $blue-light;
    }
}

.userMenu_nav {
    color: $black-light;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    overflow: hidden;
    position: absolute;
    width: rem(150px);
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
    transition: opacity $speed $easing, visibility 0s $speed, transform $speed $easing;

    @media (max-width: $to-small) {
        top: calc(100% + 5px);
        right: -5px;
    }

    @media (min-width: $from-small) {
        bottom: 0;
        left: calc(100% - #{rem(20px)});
    }

    .userMenu.is-open & {
        opacity: 1;
        transform: none;
        visibility: visible;
        transition-delay: 0s;
    }
}

.userMenu_name {
    background-color: $green;
    font-size: rem(16px);
    font-weight: $bold;
    color: $white;
    line-height: 1.2;
    padding: rem(10px) rem(10px);
}

.userMenu_list {
    @include u-list-reset;

    // padding: rem(10px) 0;
}

.userMenu_list_item {
}

.userMenu_list_link {
    color: $black;
    display: block;
    min-width: inherit;
    max-width: inherit;
    padding: rem(10px) rem(10px);
    text-align: left;
    width: 100%;
    transition: background-color $speed $easing;

    &.-red {
        color: $red !important;
    }

    &:hover {
        color: inherit;
        background-color: $gray;
    }
}

.userMenu_close {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: rem(15px);
    right: rem(25px);
    opacity: 0.3;
    transition: opacity $speed-fast;
    cursor: pointer;


        display: none;
    .userMenu.is-open & {
        display: block;
    }

    &:hover {
        opacity: 0.5;
    }
}
