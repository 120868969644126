@import "../../styles/settings";

.icon {
    display: block;
    width: 100%;
    height: 100%;

    svg {
        display: block;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}
