@import "../../styles/settings";

.formLabel {
    color: $gray-dark;
    display: block;
    margin-bottom: rem(8px);
    font-size: rem(16px);

    &.-small {
        font-size: rem(12px);
    }

    .formItem.has-error & {
        color: $red;
    }
}

.formLabel_required {
    color: $red;
}
