@import "../../styles/settings";

.cardProgress {

}

.cardProgress_header {
    background-color: $green;
    border-radius: $radius;
    text-align: center;
    color: $black;
    padding: rem(30px) 0;

    .cardProgress.is-completed & {
        color: $white;
        background-color: $blue;
    }

    .cardProgress.is-not-started & {
        background-color: $gray-dark;
    }
}

.cardProgress_header_container {
    display: inline-block;
    width: 100%;
    max-width: rem(425px);

    @media (max-width: $to-small) {
        padding: 10px $padding-small;
    }

    @media (min-width: $from-small) {
        padding: rem(20px);
    }
}

.cardProgress_header_title {
    margin-bottom: rem(25px);
}

.cardProgress_header_date {
    display: block;
    opacity: 0.6;
    margin-top: rem(5px);
}

.cardProgress_header_item {
    margin-bottom: rem(20px);
}

.cardProgress_header_signature {
    height: rem(140px);
    background-color: $white;
    border-radius: $radius;
}

.cardProgress_titles {
    padding: rem(20px) 0;
    border-bottom: 2px solid $gray;

    @media (max-width: $to-small) {
        display: none;
    }
}

.cardProgress_link {
    display: block;
    border-bottom: 1px solid $gray;
    color: $gray-dark;
    display: flex;
    align-items: center;

    @media (max-width: $to-small) {
        padding: 5px 0;
        min-height: 40px;
    }

    @media (min-width: $from-small) {
        padding: rem(10px) 0;
        min-height: rem(60px);
    }

    &:hover {
        color: $color;
    }

    .is-done & {
        color: $green;
    }

    .is-current & {
        color: $blue;
    }
}
