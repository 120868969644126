@import "../../styles/settings";

.lightbox {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;
    visibility: hidden;
    transition: visibility 0s $speed-medium;

    @media (max-width: $to-small) {
        padding-top: 40px;
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        padding-left: rem($header);
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        padding-left: calc(20.2% + #{rem($header)});
    }

    @media (min-width: $from-huge) {
        padding-left: calc(20.4% + #{rem($header)});
    }

    &.is-open {
        visibility: visible;
        transition-delay: 0s;
    }
}

.lightbox_bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $black;
    opacity: 0;
    transition: opacity $speed-medium $easing;

    .lightbox.is-open & {
        opacity: 0.5;
    }
}

.lightbox_main {
    position: relative;
    width: 100%;
    color: $white;
    padding: rem(40px) rem(30px);
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    @include overflow-scrolling;
    opacity: 0;
    transform: translateY(100%);
    transition: transform $speed-medium $easing, opacity $speed $easing;

    @media (max-width: $to-small) {
        background-color: $black;
    }

    @media (min-width: $from-small) {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $black;
            margin: rem(10px);
            z-index: -1;
            border-radius: $radius;
        }
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        right: rem(-60px);
    }

    .lightbox.is-open & {
        transform: translateY(0);
        opacity: 1;
    }
}

.lightbox_header {
    margin-bottom: rem(30px);
}

.lightbox_footer {
    margin-top: rem(30px);
}

.lightbox_button {
    @media (max-width: $to-small) {
        margin-bottom: 20px;
    }
}

.lightbox_close {
    position: absolute;

    svg {
        stroke: $white;
        transition: stroke $speed $easing;
    }

    &:hover {
        svg {
            stroke: $green-light;
        }
    }

    @media (max-width: $to-small) {
        top: -30px;
        right: 20px;
        width: 25px;
        height: 25px;
    }

    @media (min-width: $from-small) and (max-width: $to-large) {
        top: rem(-50px);
        right: rem(10px);
    }

    @media (min-width: $from-small) {
        width: rem(40px);
        height: rem(40px);
    }

    @media (min-width: $from-large) {
        top: rem(10px);
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        right: 1.5vw;
    }

    @media (min-width: $from-huge) {
        right: 2.5vw;
    }
}
