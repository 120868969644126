@import "../../styles/settings";

.cardHeading {
    // padding-bottom: rem(15px);
    border-bottom: 1px solid $gray;
    font-size

    &.-noMargin {
        margin-bottom: 0;
    }

    &.-largePadding {
        padding: rem(20px) rem(20px) rem(30px) rem(20px);
        border-bottom: 1px solid $gray;
        margin-bottom: rem(30px);

        @media (min-width: $from-medium) {
            padding: rem(20px) rem(50px) rem(35px) rem(50px);
        }
    }

    &.-light {
        color: $gray-light;

        .-h2 {
            font-size: rem(45px);
        }
    }

    &.-noBorder {
        border-bottom: none;
    }

    @media (max-width: $to-medium) {
        padding-bottom: rem(15px);
    }
    @media (min-width: $from-medium) {
        padding-bottom: rem(48px);
    }
}

.cardHeading_title {
    font-size: rem(32px);
    font-family: $font-serif;
}