@import "../../styles/settings";

.projectList {
    padding-bottom: rem(24px);
}

.projectList_heading {
    font-size: rem(28px);
    padding: rem(16px) 0 rem(24px);
    line-height: 1;
    border-bottom: 1px solid #F0F0F0;
}

.projectList_filters {
    margin: rem(24px) 0;
}

.projectList_filters_button {
    color: $black;
    padding: rem(12px);
    position: relative;
    font-size: rem(16px);

    display: inline-flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    &.is-active {
        background-color: rgba($blue-new, 10%);
        border-radius: 4px;
        color: $blue-new;
    }

    &.-red {
        color: $red;
    }

    & + & {
        margin-left: rem(12px);
    }
}

.projectList_filters_label {
    margin-right: rem(12px);
    line-height: 1.5;
}

.projectList_filters_number {
    background-color: $black;
    border-radius: 4px;
    color: $white;
    font-weight: $bold;
    font-size: rem(11px);
    padding: 4px 5px;

    .projectList_filters_button.is-active & {
        background-color: $blue-new;
    }
}

// .projectList_create {
//     position: fixed;
//     @media (max-width: $to-small) {
//         bottom: rem(20px);
//         right: rem(15px);
//     }
//     @media (min-width: $from-small) {
//         bottom: rem($unit);
//         right: rem($unit);
//     }
// }
