@import "../../styles/settings";

.content {
    p + p {
        margin-top: rem(20px);
    }

    ul {
        padding-left: rem(20px);
    }

    li + li {
        margin-top: rem(10px);
    }

    img {
        margin-bottom: rem(25px);
        width: 100%;
    }
}
