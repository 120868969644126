@import "../../styles/settings";

.modalActions {

}

.modalActions_item {
    display: inline-block;
    vertical-align: middle;
    margin-right: rem(10px);
}
