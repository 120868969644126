@import "../../styles/settings";

.loadingError {
    background-color: rgba($red, 0.5);
    border: 1px solid rgba($red, 0.5);
    border-radius: $radius;
    color: darken($red, 30%);
    margin: rem(15px) 0;
    padding: rem(15px);
}
