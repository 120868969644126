@import "../../styles/settings";

.spinner {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    height: 4px;
    transition: opacity $speed $easing;

    .has-transition-enter-done & {
        opacity: 0;
    }
}

.spinner_main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(1);
    transform-origin: center left;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $green;
        content: "";
        transform: scaleX(0);
        transform-origin: center left;
        animation: loading 2s $easing infinite forwards;
    }
}

@keyframes loading {
    0% {
        transform: translateX(0%) scaleX(0);
    }

    30% {
        transform: translateX(0%) scaleX(1);
    }

    80% {
        transform: translateX(100%) scaleX(0);
    }

    100% {
        transform: translateX(100%) scaleX(0);
    }
}
