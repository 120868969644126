@import "../../styles/settings";

.sidebar {
    position: relative;
    background-color: $white;
    color: $black;
    height: 100%;
    padding: rem(16px) rem(12px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar_logo {
    text-align: left;
    width: rem(117px);
    height: rem(28px);
    margin-bottom: rem(24px);
}

.sidebar_nav {
    &.-top {
        padding-top: rem(24px);
        border-top-width: 1px;
        border-top: 1px solid #F0F0F0;
    }

    &.-bottom {
        padding-bottom: rem(16px);
        border-bottom: 1px solid #F0F0F0;
    }
}

.sidebar_nav_list {
    @include u-list-reset;
}

.sidebar_nav_item {
    & + & {
        margin-top: rem(4px);
    }
}

.sidebar_link {
    color: $black;
    padding: rem(12px);
    position: relative;
    font-size: rem(16px);

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    &.-active {
        background-color: rgba($blue-new, 10%);
        border-radius: 4px;
        color: $blue-new;
    }

    &.-red {
        color: $red;
    }
}

.sidebar_link_icon {
    width: rem(24px);
    height: rem(24px);
}

.sidebar_link_label {
    margin-left: rem(12px);
}

.sidebar_link_number {
    background-color: $blue-new;
    border-radius: 4px;
    color: $white;
    font-weight: $bold;
    font-size: rem(11px);
    padding: 4px 5px;
    position: absolute;
    right: rem(12px);
}

.sidebar_user {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-top: rem(16px);
}

.sidebar_user_avatar {
    width: rem(40px);
    height: rem(40px);
    border-radius: 50%;
}

.sidebar_user_text {
    padding-left: rem(12px);
}

.sidebar_user_name {
    font-size: rem(16px);
    font-weight: 400;
    margin: 0;
}

.sidebar_user_email {
    color: $gray-dark;
    font-size: rem(12px);
    margin: 0;
}

.sidebar_signature {
    color: #898989;
    font-size: rem(12px);
    padding-top: rem(16px);
}