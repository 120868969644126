@import "../../styles/settings";

.link {
    color: $black;

    &.-block {
        position: relative;
        display: inline-block;
        color: $color;
        transition: color $speed $easing, background-color $speed $easing;
        padding: 0 rem(5px) rem(2px);

        &:hover {
            color: $white;
            background-color: $blue;
        }
    }

    &.-blue {
        color: $blue;
    }

    &.-gray {
        color: lighten($black,50%);
    }

    &.-classic {
        position: relative;
        color: currentColor;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            border-bottom: 1px solid currentColor;
            transform: scale(1);
            transition: transform $speed $easing;
        }

        &:hover {
            &::after {
                transform: scale(0.8);
            }
        }
    }

    &.-input {
        position: relative;
        display: flex;
        width: 100%;
        height: rem(60px);
        font-size: rem(18px);
        color: $white;
        text-align: left;
        align-items: center;
        justify-content: flex-start;
        padding: 0 rem(20px);
        border-radius: $radius;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $black;
            opacity: 0.13;
            border-radius: $radius;
            transition: opacity $speed $easing;
        }

        &:hover {
            color: $white;

            &::before {
                opacity: 0.2;
            }
        }

        svg {
            fill: $white;
        }
    }

    &.-italic {
        font-style: italic;
    }
}

.link_icon {
    position: relative;
    width: rem(17px);
    height: rem(17px);
    margin-right: rem(20px);
    flex-shrink: 0;
}

.link_label {
    position: relative;
    @include u-truncate;
}
