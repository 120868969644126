@import "../../styles/settings";

.cardMain {
    position: relative;
    // padding: rem(30px) rem(20px);
    padding: rem(16px) 0 rem(48px);

    .card.-auth & {
        // padding: 0 rem(20px) rem(40px) rem(20px);

        @media (min-width: $from-medium) {
            // padding: 0 rem(50px) rem(60px) rem(50px);
        }
    }
}
