@import "../../styles/settings";

.validator {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    background-color: $white;
    z-index: 10000;
    padding: rem($unit-half) rem($unit * 1.5);
    transform: translate3d(0, 100%, 0);
    transition: transform $speed $easing;
    box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.3);

    &.is-open {
        transform: translate3d(0,0,0);
    }
}

.validator_actions_item {
    display: inline-block;
    margin-right: rem(20px);
}

.validator_button_inner {
    pointer-events: none;
}
