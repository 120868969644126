@use "sass:math";
@import "../../styles/settings";

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 700;
    visibility: hidden;
    transition: visibility 0s $speed-medium;
    pointer-events: none;

    @media (min-width: $from-small) {
        padding-left: rem($header);
    }

    &.is-open {
        visibility: visible;
        transition-delay: 0s;
        pointer-events: all;
    }
}

.modal_overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $black;
    opacity: 0;
    transition: opacity $speed-medium $easing;
    overflow: hidden;

    .modal.is-open & {
        opacity: 0.5;
    }
}

.modal_panel {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: $white;
    transform: translate3d(100%,0,0);
    transition: transform $speed-medium $easing;
    font-size: rem(16px);
    text-align: left;

    @media (max-width: $to-small) {
        padding: $padding-small 0;
        width: 100%;
    }
    @media (min-width: $from-small) and (max-width: $to-big) {
        width: 75vw;
    }
    @media (min-width: $from-big) {
        width: 50vw;
    }

    .modal.is-open & {
        transform: translate3d(0,0,0);
    }
}

.modal_container {
    max-height: calc(100vh - #{rem(120px)});
    overflow-y: auto;
    @include overflow-scrolling;
}

.modal-heading {
    background-color: $white;
    border-bottom: 1px solid $gray;
}

.modal-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.modal-list_item {
    padding: rem($unit-small) rem($unit-half);
    border-bottom: 1px solid $black;
    position: relative;
}

.modal-list_item_title {
    font-size: rem(18px);
    margin: 0;
}

.modal-list_item_inner {
    display: flex;
    justify-content: space-between;

    @media (min-width: $from-medium) {
        padding: 0 rem($unit-small * 2);
    }

    @media (max-width: $to-medium) {
        padding: 0 0 0 rem($unit-small*2);
    }

    .modal-list_item.-single & {
        padding-left: rem(math.div($unit-small,2));
    }
}

.modal-list_item_content {
    max-width: 65%;
}

.modal-list_item_controls {
    position: absolute;
    top: 50%;
    left: rem($unit-half);
    transform: translate3d(0, -50%, 0);
}

.modal-list_item_controls_button {
    display: block;
}

.modal-list_item_actions {
    display: flex;
    flex-direction: row;
}

.modal-list_item_title {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
}

.modal-list_item_action {
    margin-left: rem(10px);
}

.modal-list_item_controls_item {
    &:first-child {
        margin-bottom: rem(10px);
    }

    &.-disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}
