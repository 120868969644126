@import "../../styles/settings";

.panel {
    background-color: $blue;
    color: $white;

    .has-transition-enter-done .view:not(.has-tutorial) & {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &.-small {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media (min-width: $from-medium) {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 10;
        left: rem($header);
        // overflow-y: auto;
        @include overflow-scrolling;
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        width: 39.8%;

        &.-small {
            width: 19.6%;
        }
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        width: 41%;

        &.-small {
            width: 20.2%;
        }
    }

    @media (min-width: $from-huge) {
        width: 41.5%;

        &.-small {
            width: 20.4%;
        }
    }

    .has-transition-enter-active & {
        opacity: 0;
    }
}
