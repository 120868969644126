@import "../../styles/settings";

.cardVideo {
    cursor: pointer;
    text-align: left;
}

.cardVideo_preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background-color: $black;
        opacity: 0.5;
        transition: opacity $speed-slow $easing;
    }

    img {
        width: 100%;
    }

    .cardVideo:hover & {
        &::after {
            opacity: 0.3;
        }
    }
}

.cardVideo_preview_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: rem(50px);
    height: rem(50px);
    margin-top: rem(-25px);
    margin-left: rem(-25px);
}

.cardVideo_title {
    margin-top: rem(20px);
}

.cardVideo_duration {
    color: $blue;
    font-size: rem(11px);
}
