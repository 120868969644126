@import "../../styles/settings";

.cardContent_section {
    & + & {
        margin-bottom: rem(40px);
    }
}

.cardContent_block {
    &.-marginTop {
        margin-top: rem(40px);

        @media (max-width: $to-small) {
            margin-bottom: 40px;
        }
    }
}
