// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$black: #000000;
$black-light: #141414;
$blue-old: #3777E3;
$blue-new: #312DFB;
$blue: $blue-new;
$blue-light: #7FA8FA;
$blue-dark: #2C68CC;
$blue-color: #174180;
$gray: #EFEFEF;
// $gray-light: #C8C8C8;
// $gray-light: #F0F0F0;
$gray-light: #FAFAFA;
$gray-medium: #979797;
// $gray-dark: #A6A6A6;
$gray-dark: #898989;
$green-old: #38B37B;
$green-new: #EAEAFF;
$green: $green-old;
$green-light: #4FF3A9;
$red: #F4574D;
$yellow: #edbb06;

// Specific
// =============================================================================
// Link
$link-color:       #1A0DAB;
$link-focus-color: #1A0DAB;
$link-hover-color: darken(#1A0DAB, 10%);
// Selection
$selection-text-color:       #3297FD;
$selection-background-color: #FFFFFF;

// Form
// =============================================================================
$input-icon-color: 424242; // No #

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
