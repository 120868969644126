@import "../../styles/settings";

.panelFooter {
    background-color: $blue-dark;
    font-size: rem(12px);

    @media (min-width: $from-small) {
        padding-left: rem(38px);
        padding-right: rem(38px);
    }
}

.panelFooter_wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    text-align: center;

    @media (max-width: $to-medium) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @media (min-width: $from-medium) {
        padding-top: rem($unit-half);
        padding-bottom: rem(30px);
        flex-direction: column;
    }

    @media (min-width: $from-medium) {
        min-height: 35.7vh;
    }
}

.panelFooter_infos {
    display: flex;
    flex-direction: column;
    margin-left: rem(3px);
    text-align: center;
}

.panelFooter_calendar {
    display: flex;
    flex-direction: column;
}

.panelFooter_icon {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: rem(-25px);

    @media (min-width: $from-tiny) {
        left: 50%;
        transform: translateX(-50%);
    }
}