@import "../../styles/settings";

.calendarDate {
    background-color: $white;
    border-radius: $radius;
    width: rem(100px);
    padding: rem(4px);
    text-align: center;
    margin: rem(30px) auto rem(15px) auto;
}

.calendarDate_header, .calendarDate_footer {
    font-weight: $medium;
    padding: rem(3px) 0;
}

.calendarDate_header {
    background-color: $blue;
    color: $white;
    text-transform: uppercase;
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}

.calendarDate_main {
    font-size: rem(40px);
    color: $color;
    padding: rem(5px) 0;
}

.calendarDate_footer {
    background-color: $gray;
    color: $color;
}
