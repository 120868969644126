@use "sass:math";
@import "../../styles/settings";

.teamMemberSelector_column {
    @media (min-width: $from-small) and (max-width: $to-medium) {
        width: math.div(1,4);
    }
    @media (max-width: $to-small), (min-width: $from-medium) {
        width: math.div(1,3);
    }

    &.-large {
        @media (min-width: $from-small) and (max-width: $to-medium) {
            width: math.div(1,2);
        }
    }
}

.teamMemberSelector_list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: rem(10px);
}

.teamMemberSelector_list_item {
    position: relative;
    padding: rem(10px) 0;
    border-bottom: 1px solid $gray-dark;

    &:first-child {
        border-top: 1px solid $gray-dark;
    }
}

.teamMemberSelector_list_item_name {
    font-size: rem(18px);
}

.teamMemberSelector_list_item_picture {
    width: rem(40px);
    height: rem(40px);
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 0;
    margin-right: rem(10px);

    &::before{
        content:"";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        background-color: $gray;
        z-index: -1;
    }

    @media (max-width: $to-small) {
        display: none;
    }
}

.teamMemberSelector_list_item_button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);
    background-color: $gray;
    color: $black;
    padding: 5px;
    border-radius: 50%;

    svg {
        width: rem(12px);
        height: rem(12px);
        stroke: $black;
    }
}
