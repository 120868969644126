@import "../../styles/settings";

.cardPreview {

}

.cardPreview_main {
    margin-bottom: rem(30px);
    margin-left: rem(5px);
}

.cardPreview_link {
    display: block;
    border-bottom: 1px solid $gray;
    color: $gray-dark;
    display: flex;
    align-items: center;

    @media (max-width: $to-small) {
        padding: 5px 0;
        min-height: 40px;
    }

    @media (min-width: $to-small) and (max-width: $to-huge) {
        min-height: rem(40px);
        padding: rem(5px) 0;
    }

    @media (min-width: $from-huge) {
        min-height: rem(55px);
        padding: rem(10px) 0;
    }

    &:hover {
        color: $color;
    }

    .is-done & {
        color: $blue;

        &:hover {
            color: darken($blue, 20%);
        }
    }

    .is-current & {
        color: $green;

        &:hover {
            color: darken($green, 30%);
        }
    }
}

.cardPreview_header {
    position: relative;
    background: $black-light;
    min-height: rem(100px);
    color: $white;
    font-weight: $medium;
    display: flex;
    justify-content: space-between;
    padding: rem(15px);
    padding-bottom: 0;
    line-height: 1.4;
}

.cardPreview_header_title {
    color: $blue-light;

    &.-link {
        &:hover {
            color: $blue-light;
            text-decoration: underline;
        }
    }
}

.cardPreview_header_button {
    position: absolute;
    right: rem(20px);
    line-height: 1;

    @media (max-width: $to-small) {
        bottom: -25px;
    }

    @media (min-width: $from-small) {
        bottom: rem(-25px);
    }
}

.cardPreview_header_date {
    margin: rem(5px) 0 0 0;
    font-size: rem(12px);
    color: darken($white, 60%);
}
