@import "../../styles/settings";

.projectBlueprintSelector {
    @media (min-width: $from-small) {
        margin-bottom: rem($unit);
    }

    &.-disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.projectBlueprintSelector_radio {
    position: relative;

    input:checked + .projectBlueprintSelector_radio_icon {
        background-color: $blue;
        color: $white;

        // Project Types
        --svg-color: white;
        --svg-background-color: #{lighten($blue, 10%)};
        --svg-border-color: none;
    }
}

.projectBlueprintSelector_radio_label {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .projectBlueprintSelector.-disabled & {
        cursor: not-allowed;
    }
}

.projectBlueprintSelector_radio_icon {
    position: relative;
    border: 1px solid currentColor;
    border-radius: 10px;
    transition: color $speed $easing, background-color $speed $easing;

    // Project Types
    --svg-color: black;
    --svg-background-color: white;
    --svg-border-color: black;

    &::before{
        content:"";
        display: block;
        padding-bottom: 100%;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        @media (max-width: $to-tiny) {
            width: rem(30px);
            height: rem(30px);
        }
        @media (min-width: $from-tiny) and (max-width: $to-medium) {
            width: rem(40px);
            height: rem(40px);
        }
        @media (min-width: $from-medium) {
            width: rem(60px);
            height: rem(60px);
        }
    }

    &.-full {
        svg {
            top: 0;
            left: 0;
            transform: none;
            width: 100%;
            height: 100%;
        }
    }
}

.projectBlueprintSelector_radio_info {
    text-align: center;
    margin: rem(10px) 0;
}
