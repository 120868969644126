@use "sass:math";

// ==========================================================================
// Utilities / Widths
// ==========================================================================

////
/// @link https://github.com/inuitcss/inuitcss/blob/6eb574f/utilities/_utilities.widths.scss
////

/// Which fractions would you like in your grid system(s)?
/// By default, the boilerplate provides fractions of one whole, halves, thirds,
/// quarters, and fifths, e.g.:
///
/// @example css
///   .u-1/2
///   .u-2/5
///   .u-3/4
///   .u-2/3
$widths-fractions: 1 2 3 4 5 6 7 8 10 !default;

@include widths($widths-fractions);

.u-1\/2\@from-small {
    @media (min-width: $from-small) {
        width: math.div(1,2) !important;
    }
}

.u-1\/2\@to-small {
    @media (max-width: $to-small) {
        width: math.div(1,2) !important;
    }
}

.u-1\/2\@to-medium {
    @media (max-width: $to-medium) {
        width: math.div(1,2) !important;
    }
}

.u-1\/2\@from-medium {
    @media (min-width: $from-medium) {
        width: math.div(1,2) !important;
    }
}

.u-1\/2\@from-large {
    @media (min-width: $from-large) {
        width: math.div(1,2) !important;
    }
}

.u-1\/3\@to-small {
    @media (max-width: $to-small) {
        width: math.div(1,3) !important;
    }
}

.u-1\/3\@from-small {
    @media (min-width: $from-small) {
        width: math.div(1,3) !important;
    }
}

.u-1\/3\@from-medium {
    @media (min-width: $from-medium) {
        width: math.div(1,3) !important;
    }
}

.u-2\/3\@to-small {
    @media (max-width: $to-small) {
        width: math.div(2,3) !important;
    }
}

.u-2\/3\@from-small {
    @media (min-width: $from-small) {
        width: math.div(2,3) !important;
    }
}

.u-1\/4\@from-small {
    @media (min-width: $from-small) {
        width: math.div(1,4) !important;
    }
}

.u-1\/4\@from-medium {
    @media (min-width: $from-medium) {
        width: math.div(1,4) !important;
    }
}

.u-1\/4\@to-medium {
    @media (max-width: $to-medium) {
        width: math.div(1,4) !important;
    }
}

.u-5\/8\@from-small {
    @media (min-width: $from-small) {
        width: math.div(5,8) !important;
    }
}

.u-5\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: math.div(5,8) !important;
    }
}

.u-3\/8\@from-small {
    @media (min-width: $from-small) {
        width: math.div(3,8) !important;
    }
}

.u-1\/8\@from-small {
    @media (min-width: $from-small) {
        width: math.div(1,8) !important;
    }
}

.u-1\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: math.div(1,8) !important;
    }
}

.u-7\/8\@from-small {
    @media (min-width: $from-small) {
        width: math.div(7,8) !important;
    }
}

.u-7\/8\@from-medium {
    @media (min-width: $from-medium) {
        width: math.div(7,8) !important;
    }
}
