@import "../../styles/settings";

// ==========================================================================
// Objects / Layout
// ==========================================================================

//
// Grid-like layout system.
//
// The layout object provides us with a column-style layout system. This file
// contains the basic structural elements, but classes should be complemented
// with width utilities, for example:
//
// @example
//   <div class="o-layout">
//     <div class="o-layout_item  u-1/1  u-1/3@medium">
//     </div>
//     <div class="o-layout_item  u-1/2  u-1/3@medium">
//     </div>
//     <div class="o-layout_item  u-1/2  u-1/3@medium">
//     </div>
//   </div>
//
// We can also manipulate entire layout systems by adding a series of modifiers
// to the `.o-layout` block. For example:
//
// @example
//   <div class="o-layout  -reverse">
//
// This will reverse the displayed order of the system so that it runs in the
// opposite order to our source, effectively flipping the system over.
//
// @example
//   <div class="o-layout  -[right|center]">
//
// This will cause the system to fill up from either the centre or the right
// hand side. Default behaviour is to fill up the layout system from the left.
//
// @requires tools/layout
// @link https://github.com/inuitcss/inuitcss/blob/0420ba8/objects/_objects.layout.scss
//

.layout {
    @include o-layout;
    width: 100%;

    // Gutter modifiers
    &.-gutter {
        margin-left: rem(-$unit);
    }

    &.-gutter-small {
        margin-left: rem(-$unit-half);
    }

    &.-spacer-top {
        margin-top: 200px;
    }

    // Horizontal aligment modifiers
    &.-center {
        text-align: center;
    }

    &.-right {
        text-align: right;
    }

    &.-reverse {
        direction: rtl;

        &.-flex {
            flex-direction: row-reverse;
        }
    }

    &.-flex {
        display: flex;

        &.-top {
            align-items: flex-start;
        }
        &.-middle {
            align-items: center;
        }
        &.-bottom {
            align-items: flex-end;
        }
    }

    &.-stretch {
        align-items: stretch;
    }

    &.-full {
        height: 100%;
    }
}

.layout_item {
    @include o-layout_item;

    // Gutter modifiers
    .layout.-gutter > & {
        padding-left: rem($unit);
    }

    .layout.-gutter-small > & {
        padding-left: rem($unit-half);
    }

    // Vertical alignment modifiers
    .layout.-middle > & {
        vertical-align: middle;
    }

    .layout.-bottom > & {
        vertical-align: bottom;
    }

    // Horizontal aligment modifiers
    .layout.-center > &,
    .layout.-right > &,
    .layout.-reverse > & {
        text-align: left;
    }

    .layout.-reverse > & {
        direction: ltr;
    }

    .layout.-full & {
        height: 100%;

        &.-empty {
            height: auto;
        }
    }

    @media (min-width: $from-medium) {
        &.-panelLarge {
            width: 44.7%;
        }

        &.-contentLarge {
            width: 55.3%;
        }

        &.-panelSmall {
            height: 100px;
            width: 22%;
        }

        &.-contentSmall {
            width: 78%;
        }
    }
}
