@import "../../styles/settings";

$select-icon-color: 000000;

.formSelect {
    position: relative;
    cursor: pointer;

    &::after {
        position: absolute;
        top: 0;
        right: rem(10px);
        bottom: 0;
        z-index: 2;
        width: 10px;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2211.3%22%20viewBox%3D%220%200%2013%2011.3%22%20enable-background%3D%22new%200%200%2013%2011.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23#{$select-icon-color}%22%20points%3D%226.5%2011.3%203.3%205.6%200%200%206.5%200%2013%200%209.8%205.6%20%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: rem(8px);
        background-repeat: no-repeat;
        content: "";
        pointer-events: none;
    }
}

.formSelect_input {
    background-color: $gray;
    padding: rem(15px) rem(10px);
    border-radius: $radius;
    position: relative;
    z-index: 1;
    padding-right: 10px;
    cursor: pointer;
}
