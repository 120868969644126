@import "../../styles/settings";

.help {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 700;
    visibility: hidden;
    transition: visibility 0s $speed-medium;

    @media (min-width: $from-small) {
        padding-left: rem($header);
    }

    &.is-open {
        visibility: visible;
        transition-delay: 0s;
    }
}

.help_overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $black;
    opacity: 0;
    transition: opacity $speed-medium $easing;

    .help.is-open & {
        opacity: 0.5;
    }
}

.help_panel {
    position: relative;
    height: 100%;
    background-color: $white;
    transform: translateX(100%);
    transition: transform $speed-medium $easing;
    font-size: rem(16px);
    overflow-y: auto;
    overflow-x: hidden;
    @include overflow-scrolling;
    text-align: left;

    @media (max-width: $to-small) {
        padding: $padding-small 0;
    }

    .help.is-open & {
        transform: translateX(0);
    }
}

.help_list {
    @include u-list-reset;
    @media (min-width: $from-medium) {
        margin-top: 10vh;
    }
    @media (max-width: $to-medium) {
        margin-top: rem(60px);
    }
}

.help_button {
    margin-top: rem(50px);

    @media (max-width: $to-small) {
        width: 60%;
    }

    @media (min-width: $from-small) {
        width: 50%;
    }
}

.help_image {
    background-color: $gray;
    border-radius: $radius;
    height: rem(240px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(25px);
}

.help_icon {
    height: rem(58px);
}

.help_content {
    max-width: rem(530px);
}

.help_link {
    display: block;
    color: $blue;
    padding: rem(14px) 0;
    transition: color $speed $easing;
    text-align: left;

    &:hover {
        color: $green-light;
    }
}

.help_container {
    opacity: 0;

    &.is-transition-exit {
        .is-open & {
            transform: translateX(-100%);
        }
    }

    &.is-transition-enter {
        .is-open & {
            height: 0;
            overflow: hidden;
            transform: translateX(100%);
        }
    }

    &.is-transition-enter-done, &.is-transition-exit {
        .is-open & {
            transition: transform $speed-medium $easing;
            opacity: 1;
            transition-delay: 0.1s;
        }
    }
}
