@import "../../styles/settings";

.signature {
    position: relative;
    background-color: $white;
    border-radius: $radius;
    color: $color;

    @media (max-width: $to-small) {
        height: 100px;
    }

    @media (min-width: $from-small) {
        height: rem(260px);
    }
}

.signature_label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: rem(12px);
    margin: rem(12px);
}

.signature_canvas {
    width: 100%;
    height: 100%;
}
