@import "../../styles/settings";

.cardList {}

.cardList_list {
    @include u-list-reset;
    border-bottom: 1px solid $gray;
    margin-bottom: rem(25px);

    .cardList.-single & {
        // border: none;
    }
}

.cardList_edit {
    width: rem(18px);
    height: rem(18px);
    margin-bottom: rem(10px);
}

.cardList_link {
    position: relative;
    display: block;
    border-top: 1px solid $gray;
    color: $color;
    padding: 0 rem(15px);
    transition: background-color $speed $easing;

    @media (max-width: $to-small) {
        padding-top: 10px;
        padding-bottom: 15px;
        line-height: 1;
    }

    @media (min-width: $from-small) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: rem(78px);

        .cardList.-single &.-url {
            flex-direction: column;
        }
    }

    &:hover {
        color: $color;
        background-color: $gray;
    }

    .cardList.-single & {
        // border: none;
    }

    .cardList.-single &.-url {
        &:hover {
            background-color: transparent;
        }
    }
}

.cardList_icon {
    flex-shrink: 0;
    width: rem(60px);
    height: rem(25px);
    margin-right: rem(30px);

    @media (max-width: $to-small) {
        margin-top: 5px;
    }
}

.cardList_main {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-right: 5px;
    z-index: 2;

    .cardList.-single & {
        text-align: center;
    }
}

.cardList_category {
    position: relative;
    color: $blue;
    font-size: rem(11px);
    z-index: 2;
    text-align: right;

    @media (max-width: $to-small) {
        display: block;
        margin-left: rem(90px);
        margin-top: 5px;
    }
}

.cardList_date {
    color: $gray-dark;
}