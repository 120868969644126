@import "../../styles/settings";

.button {
    position: relative;
    border-radius: $radius;
    font-weight: $medium;
    text-align: center;
    transition: background-color $speed $easing, opacity $speed $easing;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    padding: 0 rem(15px);

    &:not(button) {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: $to-small) {
        height: 40px;
    }

    @media (min-width: $from-small) {
        height: rem(40px);
    }

    &.-black {
        background-color: $black-light;
        color: $white;

        &:hover, &:focus {
            background-color: lighten($black-light, 20%);
        }
    }

    &.-gray {
        background-color: $gray;
        color: $black;

        &:hover,
        &:focus {
            background-color: lighten($black-light, 80%);
        }
    }

    &.-gray-dark {
        background-color: $gray-dark;
        color: $gray-medium;

        &:hover,
        &:focus {
            background-color: lighten($black, 80%);
        }
    }

    &.-white {
        background-color: $white;

        &:hover,
        &:focus {
            background-color: $gray;
        }
    }

    &.-blue {
        background-color: $blue;
        color: white;

        &:hover,
        &:focus {
            background-color: lighten($blue, 10%);
        }
    }

    &.-red {
        background-color: $red;
        color: $white;

        &:hover,
        &:focus {
            background-color: lighten($red, 10%);
        }
    }

    &.-green {
        background-color: $green;
        color: $black;

        &:hover, &:focus {
            background-color: lighten($green, 10%);
        }
    }

    .fab.is-open & {
        background-color: $red;
    }

    &.-greenLight {
        background-color: $green-light;
        color: $color;

        &:hover, &:focus {
            background-color: lighten($green-light, 15%);
        }
    }

    &.-min {
        min-width: rem(160px);
    }

    &.-circle {
        border-radius: 50%;
        padding: 0;

        @media (max-width: $to-small) {
            width: 50px;
            height: 50px;
        }

        @media (min-width: $from-small) {
            width: rem(50px);
            height: rem(50px);
        }
    }

    &.-shadow {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.27);
    }

    &.-block {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &.-large {
        height: rem(75px);
        font-size: rem(18px);
    }

    &.-medium {
        height: rem(60px);
    }

    &.-small {
        height: rem(40px);
    }

    &.-xsmall {
        font-size: 0.8rem;
        height: rem(30px);
        padding-left: rem(10px);
        padding-right: rem(10px);

        &.-icon {
            padding-left: rem(30px);

            .button_icon {
                left: rem(10px);
            }
        }
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.-icon {
        padding-left: rem(35px);

        .button_icon {
            color: $white;
            left: rem(15px);

            svg {
                stroke: currentColor;
            }
        }
    }

    &.-spinner {
        .button_inner:before {
            content: '';
            position: absolute;
            top: 50%; left: 50%;
            width: rem(30px);
            height: rem(30px);
            animation: spinLoadButton 0.5s infinite linear 0.25s;
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
            border-radius: 50%;
            border: solid 2px $white;
            border-bottom-color: transparent;
            border-right-color: transparent;
            border-left-color: transparent;
            z-index: 1;
            visibility: hidden;
            opacity: 0;
        }

        &.-xsmall {
            .button_inner:before {
                width: rem(20px);
                height: rem(20px);
            }
        }

        &.is-loading {
            pointer-events: none;

            &::before {
                transform: scaleY(1);
                transform-origin: center bottom;
            }
            .button_inner:before {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.-max-width {
        max-width: 650px;
        display: inline-block;
    }
}

.button-label {
    position: relative;
    display: inline-block;
    text-align: left;
    background-color: $white;
    padding: rem(15px) rem(20px) rem(15px) rem(55px);
    border-radius: rem(25px);
    width: auto;
    color: $black;

    &:hover {
        color: $black;
    }
}

.button_inner {
    // transition: color 0.3s $Power2EaseOut;
    position: relative;
    display: block;
    transform: translate3d(0,0,0);

    .button.is-loading & {
        color: transparent;
    }
}

@keyframes spinLoadButton {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}


.button_icon {
    position: absolute;
    top: 0;
    bottom: 0;
    width: rem(12px);

    @media (max-width: $to-small) {
        left: 15px;
    }

    @media (min-width: $from-small) {
        left: rem(25px);
    }

    svg {
        stroke: $black;
        transition: stroke $speed $easing;
    }

    .button-label & {
        width: rem(40px);
        height: rem(40px);
        top: 50%;
        bottom: auto;
        left: rem(5px);
        background-color: darken($white,10%);
        border-radius: 50%;
        transform: translateY(-50%);
        transition: background-color $speed $easing;
    }

    .button-label:hover & {
        background-color: $blue;

        svg {
            stroke: $white;
        }
    }
}

.button-circle {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: rem(20px);

    &::before{
        content:"";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        background-color: $blue;
        z-index: -1;
        transition: transform $speed $easing;
    }

    &:hover,
    .button-wrap:hover & {
        &::before{
            transform: scale3d(1.1, 1.1, 1.1);
        }
    }

    &.-border {
        &::before{
            background-color: transparent;
            border: 1px solid $black;
        }

        svg {
            fill: $black;
        }
    }

    &.-border-blue {
        &::before{
            background-color: transparent;
            border: 1px solid $blue;
        }

        svg {
            fill: $blue;
        }
    }

    &.-small {
        padding: rem(12px);

        svg {
            width: rem(20px);
            height: rem(20px);
        }
    }

    &.-medium {
        padding: rem(20px);

        svg {
            width: rem(28px);
            height: rem(28px);
        }
    }

    &.-gray {
        &::before{
            background-color: $gray;
        }

        svg {
            fill: $black;
        }

    }

    &.-white {
        &::before{
            background-color: $white;
        }

        svg {
            fill: $black;
        }

    }

    svg {
        width: rem(40px);
        height: rem(40px);
        fill: $white;
    }
}

.button-wrap:hover {
    cursor: pointer;
}
