@import "../../styles/settings";

.panelContainer {
    .panel.-small & {
        @media (max-width: $to-small) {
            padding-left: $padding-small;
            padding-right: $padding-small;
        }

        // @media (min-width: $from-small) {
        //     padding-right: rem(10px);
        // }

        @media (min-width: $from-small) and (max-width: $to-medium) {
            padding: 0 10vw;
        }

        @media (min-width: $from-medium) {
                padding: 0 rem(10px);
        }

        // @media (min-width: $from-medium) and (max-width: $to-large) {
        //     padding-left: 2.5vw;
        // }

        // @media (min-width: $from-large) {
        //     padding-left: 4vw;
        // }
    }
}
