@import "../../styles/settings";

.formInput {
    padding: rem(12px) rem(10px);
    height: rem(60px);
    background-color: $gray;
    border-radius: $radius;
    font-size: rem(16px);
    transition: background-color $speed $easing, border-color $speed $easing;
    border: 2px solid $gray;

    &:focus {
        background-color: darken($gray, 5%);
        border-color: darken($gray, 5%);
    }

    &.-blue {
        background-color: $blue-dark;
        border-color: $blue-dark;
    }

    &.-blue-light {
        background-color: #EBEFF7;
        border: 1px solid #C6CFE2;
    }

    &.-small {
        height: rem(46px);
    }

    &::placeholder {
        color: $color;
    }

    .formItem.has-error & {
        border-color: $red;
    }
}
