@import "../../styles/settings";

.fab {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 800;
    transform: translateY(100%);
    transition: transform 0s $easing;

    @media (max-width: $to-small) {
        margin: 10px;
    }

    @media (min-width: $from-small) {
        padding: rem(20px);
    }

    svg {
        fill: $white;
    }

    .has-transition-appear-active &,
    .has-transition-enter-active &,
    .has-transition-enter-done &{
        transform: none;
    }

    .has-transition-appear-active & {
        transition-duration: $speed;
        transition-delay: 0.6s;
    }
}

.fab_icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform $speed $easing, opacity $speed $easing;

    .fab.is-open & {
        opacity: 0;
        transform: scale(0);
    }

    &.-hidden {
        opacity: 0;
        transform: scale(0);

        .fab.is-open & {
            opacity: 1;
            transform: scale(1);
        }
    }

    .icon.-close {
        padding: rem(16px);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: rem(20px);
            height: rem(20px);
            stroke-width: 2px;
            stroke: $white !important;
        }
    }
}
