@use "sass:math";

// ==========================================================================
// Settings / Config
// ==========================================================================

// Context
// =============================================================================
// The current stylesheet context. Available values: frontend, editor.
$context: frontend  !default;

// Path is relative to the stylesheets directory.
$assets-path: "../"  !default;

// Typefaces
// =============================================================================
// Font directory
$font-dir: "../fonts/";

$font-sans-serif: Helvetica Neue, sans-serif;
$font-serif: "LocomotiveNew", $font-sans-serif;

// Typography
// =============================================================================
// Base
// $font-size:   14px;
$font-size:   16px;
$line-height: math.div(20px, $font-size);
$font-family: $font-sans-serif;
$color:       #222222;
$radius: 4px;

// Weights
$light:  300;
$normal: 400;
$medium: 500;
$bold:   700;

// Transitions
// =============================================================================
$speed:  0.3s;
$speed-fast: ($speed * 0.5);
$speed-medium: ($speed * 1.5);
$speed-slow: ($speed * 2);
$speed-slower: ($speed * 3);
$speed-slowest: ($speed * 4);
$easing: $Power2EaseOut;
$bounce: cubic-bezier(0.17, 0.67, 0.3, 1.33);

// Spacing Units
// =============================================================================
$unit:       60px;
$unit-half:  math.div($unit, 2);
$unit-small: 40px;
$side-width: 280px;
$side-width-small: 230px;

// Container
// ==========================================================================
$container-width: 2000px;
$padding:         $unit;
$padding-small: 20px;

$header: 120px;
$modifier-huge: 1.125;

// Breakpoints
// =============================================================================
$from-tiny:     500px              !default;
$to-tiny:       $from-tiny - 1     !default;
$from-small:    700px              !default;
$to-small:      $from-small - 1    !default;
$from-medium:   1000px             !default;
$to-medium:     $from-medium - 1   !default;
$from-large:    1200px             !default;
$to-large:      $from-large - 1    !default;
$from-big:      1400px             !default;
$to-big:        $from-big - 1      !default;
$from-huge:     1600px             !default;
$to-huge:       $from-huge - 1     !default;
$from-enormous: 1800px             !default;
$to-enormous:   $from-enormous - 1 !default;
$from-gigantic: 2000px             !default;
$to-gigantic:   $from-gigantic - 1 !default;
$from-colossal: 2400px             !default;
$to-colossal:   $from-colossal - 1 !default;
