@import "../../styles/settings";

.container {
    position: relative;
    width: 100%;

    @media (max-width: $to-small) {
        padding: 0 $padding-small;
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        padding: 0 10vw;
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        padding: 0 4vw;
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        padding: 0 5vw;
    }

    @media (min-width: $from-huge) {
        padding: 0 7vw;
    }
    @media (min-width: $from-gigantic){
        padding: 0 rem($unit*1.5);
    }

    &.-refresh {
        padding: 0 rem(24px);
    }
}
