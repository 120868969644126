@import "../../styles/settings";

.panelNav {
    @media (max-width: $to-medium) {
        margin-right: rem(38px);
        margin-bottom: 80px;
    }
}

.panelNav_list {
    @include u-list-reset;
}

.panelNav_link {
    position: relative;
    display: block;
    color: $white;
    font-size: rem(16px);
    padding: rem(8px);
    padding-left: rem(38px);
    transition: color $speed $easing, background-color $speed $easing;
    border-radius: $radius;
    border-bottom: 1px solid $blue;

    // &.is-done {
    //     color: $green-light;

    //     &:hover {
    //         color: $green-light;
    //     }
    // }

    &:hover, &.is-active {
        color: $white;
    }

    &:hover:not(.is-active) {
        background-color: rgba($blue-light, 0.4);
    }

    &.is-upcoming {
        color: rgba(255,255,255,0.5);
    }

    &.is-current {
        color: $green-light;
    }

    &.is-active {
        background-color: $blue-dark;
    }
}

.panelNav_icon {
    position: absolute;
    top: 50%;
    left: rem(10px);
    width: rem(15px);
    height: rem(15px);
    margin-top: rem(-7px);

    // svg {
    //     stroke: $green-light;
    // }

    .panelNav_link.is-current & {
        fill: $green-light;
    }
}
