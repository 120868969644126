.has-transition {
    position: relative;

    &:not(:first-child) {

    }
}

.has-transition-enter-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
