@use "sass:math";
@import "../../styles/settings";

$darker-blue: #3a73e3;
$modifier-huge: 1.125;
$play-button: 114px;
$play-button-huge: math.div(150px, $modifier-huge);

.lisa {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: $white;
    z-index: 800;
    pointer-events: none;
    overflow: hidden;

    @media (max-width: $to-medium) {
        z-index: 1000;
    }

    &::after {
        content:"";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $blue;
        z-index: -1;
        transform: scaleY(0);
        transform-origin: center bottom;
        transition: transform 0.6s $Power2EaseInOut 0.3s;
    }

    &.is-active {
        opacity: 1;
        pointer-events: all;
        transition-delay: 0s;

        &::after {
            transform: scaleY(1);
            transition-delay: 0s;
        }
    }
}

// ==========================================================================
// CONTENT
// ==========================================================================

.lisa_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

// STEP
// ==========================================================================
.lisa_button {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: rem(20px);
    background-color: white;
    min-width: rem(200px);
    padding: rem(25px) !important;
    border-radius: rem(50px);
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    line-height: normal;
    cursor: pointer;
    user-select: none;
    color: $black;

    &:before {
        background-color: $white;
    }

    &:after {
        background-color: darken($white, 10%);
    }

    @media (max-width: $to-huge) {
        font-size: rem(17px);
        padding: rem(24px) rem(34px);

        &.-min {
            min-width: rem(170px);
        }
    }

    @media (min-width: $from-huge) {
        font-size: rem(math.div(22px, $modifier-huge));
        padding: rem(27px) rem(37px);

        &.-min {
            min-width: rem(math.div(225px, $modifier-huge));
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;
    }

    &::after {
        content: "";
        z-index: -1;
        transition: transform $speed-medium $easing, border-radius $speed-medium $easing;
        transform: translateY(100%);
        transform-origin: center bottom;
        border-radius: 70%;
    }

    &:hover {
        &::after {
            transform: translateY(0);
            border-radius: 0%;
        }
    }
}

.lisa_button_label {
    position: relative;
    pointer-events: none;

    &.-base {
        display: block;
        transition: transform $speed-medium $easing 0.1s;

        .lisa_button:hover & {
            transform: translateY(-250%);
            transition-delay: 0s;
        }
    }

    &.-hover {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%) translateY(250%);
        transition: transform $speed-medium $easing;

        .lisa_button:hover & {
            transform: translate(-50%, -50%);
            transition-delay: 0.1s;
        }
    }
}

.lisaStep {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    opacity: 0;
    transform: translate(-50%,60px);
    transition: transform $speed-slower $Power2EaseOut, opacity $speed-slower $Power2EaseOut;

    &.-active {
        pointer-events: all;
    }

    @media (min-width: $from-gigantic) {
        max-width: 1600px;
    }

    @media (min-width: $from-huge) and (max-width: $to-gigantic) {
        max-width: 1200px;
    }

    @media (min-width: $from-huge) {
        margin: auto;
        left: 50%;
    }

    @media (min-width: $from-big) and (max-width: $to-huge) {
        padding: 0 rem($unit*6);
    }

    @media (max-width: $to-big) {
        padding: 0 rem($unit*2);
    }

    @media (max-width: $to-small) {
        padding: 0 20px;
    }

    &.is-transition-exit {
        transform: translate(-50%,60px);
        opacity: 0;
    }

    &.is-transition-leave,
    .lisa:not(.is-active) & {
        transform: translate(-50%, 60px);
        opacity: 0;
    }

    &.is-transition-enter-done{
        transform: translate(-50%,0);
        opacity: 1;
    }
}

.lisaStep_choices {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $from-small) {
        flex-direction: row;
        margin-top: rem(60px);
    }
}

.lisaStep_title {
    display: block;
    text-align: center;
    line-height: 1.3;

    &:before {
        position: absolute;
        content: attr(data-subtitle);
        font-size: rem(16px);
        top: 0;
        left: 50%;
        display: block;
        transform: translate3d(-50%, -100%, 0) translateY(rem(-$unit-small));
        transform-origin: center bottom;

        @media (max-width: $to-small) {
            font-size: 14px;
            transform: translate3d(-50%, -100%, 0) translateY(-20px);
        }
    }

    @media (min-width: $from-medium) {
        font-size: rem(45px);
    }

    @media (max-width: $to-medium) {
        font-size: rem(40px);
    }

    @media (min-width: $from-huge) {
        width: 80%;
    }

    @media (max-width: $to-small) {
        width: calc(100% - 40px);
        position: relative;
        font-size: 21px;
        transform: none;
        top: auto;
        left: auto;
        margin-bottom: rem($unit-small);
    }
}