@import "../../styles/settings";

.section {
    @media (max-width: $to-small) {
        padding: 20px 0;
    }

    @media (min-width: $from-small) and (max-width: $to-big) {
        padding: 4vh 0;
    }

    @media (min-width: $from-big) and (max-width: $to-huge) {
        padding: 6vh 0;
    }

    @media (min-width: $from-huge) {
        padding: 5.5vh 0;
    }
}
