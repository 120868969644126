@import "../../styles/settings";

.formInputFile {
    position: relative;
    text-align: center;
    border-radius: $radius;
    display: flex;
    align-items: center;
    text-align: center;
    min-height: rem(100px);

    &.-full-height {
        min-height: auto;

        &::before {
            content:"";
            display: block;
            padding-bottom: 66%;
        }
    }

    &:hover {
        cursor: pointer;
    }

    .filepond--root {
        font-family: inherit;
    }

    .filepond--drop-label {
        color: $black;
        height: 100%;

        label {
            cursor: pointer;
        }
    }

    .filepond--label-action {
        text-decoration-color: currentColor;
    }

    .filepond--drip {
        color: $color;
        background-color: $gray;
        opacity: 1;

        &:before {
            content: '';
            position: absolute;
            top: 8px; left: 8px; right: 8px; bottom: 8px;
        }
    }

    .filepond--item-panel {
        background-color: $gray-dark;
    }

    .filepond--drip-blob {
        background-color: $black;
    }

    .filepond--file-action-button {
        background-color: $gray;
        color: $black;
        cursor: pointer;
    }

    .filepond--file {
        color: $color;
    }

    .filepond--item[data-filepond-item-state="load-invalid"] {
        .filepond--file-status {
            color: $red;
        }
    }
}

.formInputFile_input {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    z-index: 2;

    &:hover {
        cursor: pointer;
    }
}
